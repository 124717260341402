import {
  ProductType as IProductType,
  ProductTypeValueEnum,
} from '@/api/generated'
import { EntityAttribute } from '@/models/common/EntityAttribute'

export class ProductType extends EntityAttribute implements IProductType {
  productTypeId!: string
  availableAreaIds?: string[]
  availableHourTypeIds?: string[]
  hasFuelSurcharge!: boolean
  defaultCalendarTypeId!: string
  defaultBrokingFee!: number
  value!: ProductTypeValueEnum

  get id(): string {
    return this.productTypeId
  }

  isBg(): boolean {
    return (
      this.isBgNoFuelSurcharge() ||
      this.isBgWithFuelSurcharge() ||
      this.isBgWithEclear() ||
      this.isBgWithEclearAndFuelSurcharge()
    )
  }

  isBgNoFuelSurcharge(): boolean {
    return this.value === ProductTypeValueEnum.BgNoFuelSurcharge
  }

  isBgWithFuelSurcharge(): boolean {
    return this.value === ProductTypeValueEnum.BgWithFuelSurcharge
  }

  isBgWithEclear(): boolean {
    return this.value === ProductTypeValueEnum.BgWithEclear
  }

  isBgWithEclearAndFuelSurcharge(): boolean {
    return this.value === ProductTypeValueEnum.BgWithEclearAndFuelSurcharge
  }

  isCfdWithEclear(): boolean {
    return this.value === ProductTypeValueEnum.CfdWithEclear
  }

  isSwapEex(): boolean {
    return this.value === ProductTypeValueEnum.SwapEex
  }

  isSwapTocom(): boolean {
    return this.value === ProductTypeValueEnum.SwapTocom
  }

  isSwapBilateral(): boolean {
    return this.value === ProductTypeValueEnum.SwapBilateral
  }

  isCfd(): boolean {
    const cfdTypes: ProductTypeValueEnum[] = [
      ProductTypeValueEnum.Cfd,
      ProductTypeValueEnum.CfdWithFuelSurcharge,
      ProductTypeValueEnum.CfdWithEclear,
    ]
    return cfdTypes.includes(this.value)
  }

  isEclear(): boolean {
    return (
      this.isBgWithEclear() ||
      this.isBgWithEclearAndFuelSurcharge() ||
      this.isCfdWithEclear()
    )
  }

  isAvailableAsCounterToEclear(): boolean {
    return this.isSwapEex() || this.isSwapTocom()
  }
}
