import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-633474d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "indication-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selected_indication = _resolveComponent("selected-indication")!
  const _component_indication_selector_modal = _resolveComponent("indication-selector-modal")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.selectedIndication)
      ? (_openBlock(), _createBlock(_component_selected_indication, {
          key: 0,
          class: "indication-selector__select-button--selected",
          "is-selectable": _ctx.isSelectable,
          "selected-indication": _ctx.selectedIndication,
          "color-reversed": _ctx.colorReversed,
          onClick: _ctx.onClick
        }, null, 8, ["is-selectable", "selected-indication", "color-reversed", "onClick"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["indication-selector__select-button--not-selected", {
        'indication-selector__select-button--not-selected-selectable': _ctx.isSelectable,
      }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, _toDisplayString(_ctx.$t('trading.message.selectIndication')), 3)),
    _createVNode(_component_base_modal, {
      title: _ctx.$t('trading.label.selectIndication'),
      name: _ctx.modalName,
      width: 1200
    }, {
      default: _withCtx(() => [
        _createVNode(_component_indication_selector_modal, {
          "fuel-surcharge-type-id": _ctx.fuelSurchargeTypeId,
          "other-delivery-terms-id": _ctx.otherDeliveryTermsId,
          "other-exchange-delivery-terms-id": _ctx.otherExchangeDeliveryTermsId,
          "other-product-sub-type": _ctx.otherProductSubType,
          position: _ctx.position,
          "color-reversed": _ctx.colorReversed,
          locale: _ctx.locale,
          onSelect: _ctx.onSelect,
          onCancel: _ctx.onCancel
        }, null, 8, ["fuel-surcharge-type-id", "other-delivery-terms-id", "other-exchange-delivery-terms-id", "other-product-sub-type", "position", "color-reversed", "locale", "onSelect", "onCancel"])
      ]),
      _: 1
    }, 8, ["title", "name"])
  ]))
}