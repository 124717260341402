
import { Form as ValidationForm } from 'vee-validate'
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { NewNegotiation } from '@/api/generated'
import BaseAlert from '@/components/common/BaseAlert.vue'
import BaseButton from '@/components/common/BaseButton.vue'
import BrokerPage from '@/components/common/BrokerPage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import CenteredLoadingContent from '@/components/common/Loading/CenteredLoadingContent.vue'
import UiStackSelector from '@/components/common/UiStackSelector.vue'
import { AlertType } from '@/components/common/constants/AlertType'
import { UiStack } from '@/components/common/constants/UiStack'
import NegotiationConfirmModalContent from '@/components/trading/NegotiationConfirmModalContent.vue'
import NegotiationIndicationsForm from '@/components/trading/NegotiationIndicationsForm.vue'
import { NegotiationFormInputMode } from '@/components/trading/constants/NegotiationFormInputMode'
import { NegotiationIndicationsFormProps } from '@/components/trading/interface/NegotiationIndicationsFormProps'
import { Organization } from '@/models/iam/Organization'
import { Product } from '@/models/trading/Product'
import { OrganizationsModule } from '@/store/modules/iam/organizations'
import { UserProfileModule } from '@/store/modules/iam/userProfile'
import { NegotiationModule } from '@/store/modules/trading/negotiation'
import { ProductModule } from '@/store/modules/trading/product'
import { setNotification } from '@/utils/utils'

const { mapGetters: userProfileMapGetters } = createNamespacedHelpers(
  'userProfile',
) as UserProfileModule

const {
  mapActions: productMapActions,
  mapState: productMapState,
} = createNamespacedHelpers('product') as ProductModule

const { mapActions: negotiationMapActions } = createNamespacedHelpers(
  'negotiation',
) as NegotiationModule

const { mapActions: organizationsMapActions } = createNamespacedHelpers(
  'organizations',
) as OrganizationsModule

export default defineComponent({
  name: 'NegotiationNew',
  components: {
    BaseAlert,
    BaseButton,
    BrokerPage,
    CenteredLoadingContent,
    ConfirmationDialog,
    NegotiationConfirmModalContent,
    NegotiationIndicationsForm,
    UiStackSelector,
    ValidationForm,
  },
  data(): {
    formValue: NegotiationIndicationsFormProps
    uiStack: UiStack
    modalName: string
    submitting: boolean
    mode: NegotiationFormInputMode
    errorType: AlertType
    creditSleeverIsShown: boolean
    eclearOrganization: Organization | undefined
  } {
    return {
      formValue: {
        askIndication: undefined,
        askProductType: undefined,
        askBrokingFee: '',
        askExchangeBrokingFee: '',
        askDefaultBrokingFees: [],
        bidIndication: undefined,
        bidProductType: undefined,
        bidBrokingFee: '',
        bidExchangeBrokingFee: '',
        bidDefaultBrokingFees: [],
        creditSleever: undefined,
        creditSleeverAskBrokingFee: '',
        creditSleeverBidBrokingFee: '',
        creditSleeverSleeveSpread: '',
      },
      uiStack: UiStack.Loading,
      modalName: 'negotiationNewConfirmModalContent',
      submitting: false,
      mode: NegotiationFormInputMode.NEW,
      errorType: AlertType.Error,
      creditSleeverIsShown: false,
      eclearOrganization: undefined,
    }
  },
  computed: {
    ...userProfileMapGetters(['userProfile']),
    ...productMapState(['productTypes']),
    eclearOrganizationId(): string | undefined {
      return this.eclearOrganization?.organizationId
    },
    eclearProductTypeIdList(): string[] {
      return this.productTypes
        .filter(p => p.isEclear() || p.isAvailableAsCounterToEclear())
        .map(p => p.productTypeId)
    },
    creditSleeverIsValid(): boolean {
      if (!this.creditSleeverIsShown) {
        return true
      }
      return !!this.formValue.creditSleever?.organizationId
    },
    product(): Product | undefined {
      return this.formValue.askIndication?.products[0] as Product | undefined
    },
  },
  async created() {
    await this.fetchProductTypes()
      .then(() => {
        this.uiStack = UiStack.Ideal
      })
      .catch(e => {
        this.uiStack = UiStack.Error
        throw e
      })
    await this.fetchEclearOrganization()
      .then(eclearOrganization => {
        this.eclearOrganization = eclearOrganization
      })
      .catch(e => {
        throw e
      })
  },
  methods: {
    ...productMapActions(['fetchProductTypes']),
    ...negotiationMapActions(['createNegotiation']),
    fetchEclearOrganization: organizationsMapActions([
      'fetchEclearOrganization',
    ]).fetchEclearOrganization as () => Promise<Organization>,
    buildPayload(): NewNegotiation | undefined {
      if (!this.formValue.askIndication) {
        return undefined
      }
      if (!this.formValue.askProductType) {
        return undefined
      }
      if (!this.formValue.bidIndication) {
        return undefined
      }
      if (!this.formValue.bidProductType) {
        return undefined
      }
      const payload = {
        askIndicationId: this.formValue.askIndication.id,
        askProductTypeId: this.formValue.askProductType.value,
        bidIndicationId: this.formValue.bidIndication.id,
        bidProductTypeId: this.formValue.bidProductType.value,
        creditSleeverOrganizationId: this.formValue.creditSleever
          ?.organizationId,
      }
      return payload
    },
    onFormInput(formValue: NegotiationIndicationsFormProps): void {
      if (
        (this.formValue.bidProductType?.value !==
          formValue.bidProductType?.value ||
          this.formValue.askProductType?.value !==
            formValue.askProductType?.value) &&
        (!!formValue.askProductType || !!formValue.bidProductType)
      ) {
        const askProductType = this.productTypes.find(
          p => p.productTypeId === formValue.askProductType?.value,
        )
        const bidProductType = this.productTypes.find(
          p => p.productTypeId === formValue.bidProductType?.value,
        )

        // 売りか買いの商品がeClearの場合、クレジットスリーバーにeClearの組織を設定する
        if (askProductType?.isEclear() || bidProductType?.isEclear()) {
          formValue.creditSleever = this.eclearOrganization
          if (!this.eclearOrganization) {
            setNotification(
              this.$t('iam.message.failGetEclearOrganization').toString(),
              'danger',
            )
          }
        }
      }
      Object.assign(this.formValue, formValue)
    },
    onConfirmClick(): void {
      this.$vfm.open(this.modalName)
    },
    async backListPage(): Promise<void> {
      await this.$router.push(`/negotiations`)
    },
    async onCancelClick(): Promise<void> {
      await this.backListPage()
    },
    async onCreateNegotiationConfirm(): Promise<void> {
      const payload = this.buildPayload()
      if (!payload) {
        return
      }
      this.submitting = true
      await this.createNegotiation(payload)
        .then(async () => {
          setNotification(
            this.$t('trading.message.successCreateNegotiation').toString(),
          )
          this.$vfm.close(this.modalName)
          await this.backListPage()
        })
        .catch(e => {
          setNotification(
            this.$t('trading.message.failCreateNegotiation').toString(),
            'danger',
          )
          throw e
        })
        .finally(() => {
          this.submitting = false
        })
    },
    onCreateNegotiationCancel(): void {
      this.$vfm.close(this.modalName)
    },
    setCreditSleeverIsShown(isShown: boolean): void {
      this.creditSleeverIsShown = isShown
    },
  },
})
