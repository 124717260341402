
import { Field } from 'vee-validate'
import { defineComponent, PropType } from 'vue'

import { PositionType } from '@/api/generated'
import BaseBox from '@/components/common/BaseBox.vue'
import BaseIcon from '@/components/common/BaseIcon.vue'
import BaseInputText from '@/components/common/BaseInputText.vue'
import BaseLabel from '@/components/common/BaseLabel.vue'
import BaseSingleSelect from '@/components/common/BaseSingleSelect.vue'
import BaseTooltip from '@/components/common/BaseTooltip.vue'
import Heading from '@/components/common/Typography/Heading.vue'
import { BaseBoxType } from '@/components/common/constants/BaseBoxType'
import { InputMode } from '@/components/common/constants/InputMode'
import { HeaderLevel } from '@/components/common/constants/TypographyLevel'
import { InputOption } from '@/components/common/interface/InputOption'
import OrganizationSelector from '@/components/iam/OrganizationSelector.vue'
import { OrganizationSelectorModalProps } from '@/components/iam/interface/OrganizationSelectorModalProps'
import FieldBrokingFee from '@/components/trading/FieldBrokingFee.vue'
import IndicationSelector from '@/components/trading/IndicationSelector.vue'
import { NegotiationFormInputMode } from '@/components/trading/constants/NegotiationFormInputMode'
import { NegotiationIndicationsFormProps } from '@/components/trading/interface/NegotiationIndicationsFormProps'
import { Indication } from '@/models/trading/Indication'
import { ProductType } from '@/models/trading/ProductType'

export default defineComponent({
  name: 'NegotiationIndicationsForm',
  components: {
    BaseBox,
    BaseIcon,
    BaseInputText,
    BaseLabel,
    BaseSingleSelect,
    BaseTooltip,
    Field,
    FieldBrokingFee,
    Heading,
    IndicationSelector,
    OrganizationSelector,
  },
  props: {
    mode: {
      type: Number,
      required: true,
      validator: (value: NegotiationFormInputMode) => {
        return [
          NegotiationFormInputMode.NEW,
          NegotiationFormInputMode.UNDER_NEGOTIATION,
          NegotiationFormInputMode.EXECUTED,
          NegotiationFormInputMode.BROKEN,
        ].includes(value)
      },
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    productTypes: {
      type: Array as PropType<ProductType[]>,
      required: true,
    },
    formValue: {
      type: Object as PropType<NegotiationIndicationsFormProps>,
      required: true,
    },
    eclearOrganizationId: {
      type: String,
      default: undefined,
    },
    eclearProductTypeIdList: {
      type: Array as PropType<string[]>,
      default: [],
    },
    isStandardSpread: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['form-input', 'credit-sleever-add', 'credit-sleever-remove'],
  data(): {
    formInputEventName: 'form-input'
    positionTypeAsk: PositionType
    positionTypeBid: PositionType
    baseBoxTypeBorder: BaseBoxType
    readonly: InputMode
    creditSleeverIsShown: boolean
    headerLevel3: HeaderLevel
  } {
    return {
      formInputEventName: 'form-input',
      positionTypeAsk: PositionType.Ask,
      positionTypeBid: PositionType.Bid,
      baseBoxTypeBorder: BaseBoxType.Border,
      readonly: InputMode.READONLY,
      creditSleeverIsShown: false,
      headerLevel3: HeaderLevel.Lv3,
    }
  },
  computed: {
    productTypeInputOptions(): InputOption[] {
      return this.productTypes.map(p => p.toInputOption())
    },
    creditSleeverEditable(): boolean {
      return [
        NegotiationFormInputMode.NEW,
        NegotiationFormInputMode.UNDER_NEGOTIATION,
      ].includes(this.mode)
    },
    inputMode(): InputMode {
      return [
        NegotiationFormInputMode.NEW,
        NegotiationFormInputMode.UNDER_NEGOTIATION,
      ].includes(this.mode)
        ? InputMode.INPUT
        : InputMode.READONLY
    },
    brokingFeeInputIsVisible(): boolean {
      return this.mode !== NegotiationFormInputMode.NEW
    },
    indicationIsSelectable(): boolean {
      return this.mode === NegotiationFormInputMode.NEW
    },
    isEclearCreditSleever(): boolean | undefined {
      const askProductType = this.productTypes.find(
        p => p.productTypeId === this.formValue.askProductType?.value,
      )
      const bidProductType = this.productTypes.find(
        p => p.productTypeId === this.formValue.bidProductType?.value,
      )
      if (askProductType || bidProductType) {
        return !!askProductType?.isEclear() || !!bidProductType?.isEclear()
      }
      return undefined
    },
    askProductIds(): string[] {
      return this.formValue.askIndication
        ? this.formValue.askIndication.products.map(
            product => product.productType.productTypeId,
          )
        : []
    },
    bidProductIds(): string[] {
      return this.formValue.bidIndication
        ? this.formValue.bidIndication.products.map(
            product => product.productType.productTypeId,
          )
        : []
    },
    askAndBidProductIds(): string[] {
      if (this.askProductIds.length === 0 && this.bidProductIds.length === 0) {
        return []
      }
      if (this.askProductIds.length === 0) {
        return this.bidProductIds
      }
      if (this.bidProductIds.length === 0) {
        return this.askProductIds
      }
      const bidProductIdsSet = new Set(this.bidProductIds)
      return [...new Set(this.askProductIds)].filter(askProductId =>
        bidProductIdsSet.has(askProductId),
      )
    },
    askProductTypeInputOptions(): InputOption[] {
      if (!this.formValue.askIndication) {
        return []
      }
      if (this.creditSleeverIsShown) {
        const askProductIds =
          this.formValue.askIndication?.products.map(
            product => product.productType.productTypeId,
          ) || []
        return this.productTypeInputOptions.filter(inputOption =>
          askProductIds.includes(inputOption.value),
        )
      }
      return this.productTypeInputOptions.filter(inputOption =>
        this.askAndBidProductIds?.includes(inputOption.value),
      )
    },
    bidProductTypeInputOptions(): InputOption[] {
      if (!this.formValue.bidIndication) {
        return []
      }
      if (this.creditSleeverIsShown) {
        const bidProductIds =
          this.formValue.bidIndication?.products.map(
            product => product.productType.productTypeId,
          ) || []
        return this.productTypeInputOptions.filter(inputOption =>
          bidProductIds.includes(inputOption.value),
        )
      }
      return this.productTypeInputOptions.filter(inputOption =>
        this.askAndBidProductIds?.includes(inputOption.value),
      )
    },
    creditSleeverSelectorRules():
      | {
          blacklist: string[]
        }
      | {
          whitelist: string[]
        } {
      const blacklist = []
      const askProductType = this.productTypes.find(
        p => p.productTypeId === this.formValue.askProductType?.value,
      )
      const bidProductType = this.productTypes.find(
        p => p.productTypeId === this.formValue.bidProductType?.value,
      )
      if ((askProductType || bidProductType) && this.eclearOrganizationId) {
        if (askProductType?.isEclear() || bidProductType?.isEclear()) {
          return { whitelist: [this.eclearOrganizationId] }
        }
        blacklist.push(this.eclearOrganizationId)
      }
      if (this.formValue.askIndication) {
        blacklist.push(this.formValue.askIndication.organizationId)
      }
      if (this.formValue.bidIndication) {
        blacklist.push(this.formValue.bidIndication.organizationId)
      }
      return { blacklist }
    },
    organizationSelectorModalQueryParams(): OrganizationSelectorModalProps['queryParams'] {
      return {
        isEclearCreditSleever: this.isEclearCreditSleever,
        isEsquareEnabled: true,
      }
    },
    askProductType(): ProductType | undefined {
      return this.productTypes.find(
        p => p.productTypeId === this.formValue.askProductType?.value,
      )
    },
    bidProductType(): ProductType | undefined {
      return this.productTypes.find(
        p => p.productTypeId === this.formValue.bidProductType?.value,
      )
    },
    askProductTypesRules(): {
      required: boolean
      whitelistForInputOption: string[]
    } {
      if (!this.creditSleeverIsShown) {
        return {
          required: true,
          whitelistForInputOption: this.formValue.bidProductType
            ? [this.formValue.bidProductType.value]
            : this.askProductIds,
        }
      }

      if (this.formValue.creditSleever?.isEClearCreditSleever) {
        return {
          required: true,
          whitelistForInputOption: this.eclearProductTypeIdList,
        }
      }

      return {
        required: true,
        whitelistForInputOption: this.askProductIds,
      }
    },
    bidProductTypesRules(): {
      required: boolean
      whitelistForInputOption: string[]
    } {
      if (!this.creditSleeverIsShown) {
        return {
          required: true,
          whitelistForInputOption: this.formValue.askProductType
            ? [this.formValue.askProductType.value]
            : this.bidProductIds,
        }
      }

      if (this.formValue.creditSleever?.isEClearCreditSleever) {
        return {
          required: true,
          whitelistForInputOption: this.eclearProductTypeIdList,
        }
      }

      return {
        required: true,
        whitelistForInputOption: this.bidProductIds,
      }
    },
    brokingFeeLabel(): string {
      return this.isStandardSpread
        ? `${this.$t('trading.label.brokingFee').toString()} (${this.$t(
            'trading.label.baseSpreadIndication',
          ).toString()})`
        : this.$t('trading.label.brokingFee').toString()
    },
    exchangeBrokingFeeLabel(): string {
      return `${this.$t('trading.label.brokingFee').toString()} (${this.$t(
        'trading.label.exchangeSpreadIndication',
      ).toString()})`
    },
  },
  watch: {
    'formValue.creditSleever'() {
      this.creditSleeverIsShown = !!this.formValue.creditSleever
    },
  },
  created() {
    this.creditSleeverIsShown = !!this.formValue.creditSleever
  },
  methods: {
    onInputAskProductType(event: HTMLSelectElement | null) {
      const defaultBrokingFee =
        event == null
          ? undefined
          : this.formValue.askDefaultBrokingFees.find(
              brokingFee => brokingFee.productTypeId === event.value,
            )
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        askProductType: event,
        askBrokingFee: defaultBrokingFee?.brokingFee.toString(),
      })
    },
    onInputBidProductType(event: HTMLSelectElement | null) {
      const defaultBrokingFee =
        event == null
          ? undefined
          : this.formValue.bidDefaultBrokingFees.find(
              brokingFee => brokingFee.productTypeId === event.value,
            )
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        bidProductType: event,
        bidBrokingFee: defaultBrokingFee?.brokingFee.toString(),
      })
    },
    onInputAskIndication(askIndication: Indication) {
      const isEclearSelected = !!this.productTypes
        .find(p => p.id === this.formValue.bidProductType?.value)
        ?.isEclear()
      const askProductType =
        askIndication.products.length === 1
          ? askIndication.products[0].productType.toInputOption()
          : isEclearSelected
          ? askIndication.products
              .find(p => p.productType.isEclear())
              ?.productType.toInputOption()
          : undefined
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        askIndication,
        askProductType,
      })
    },
    onInputBidIndication(bidIndication: Indication) {
      const isEclearSelected = !!this.productTypes
        .find(p => p.id === this.formValue.askProductType?.value)
        ?.isEclear()
      const bidProductType =
        bidIndication.products.length === 1
          ? bidIndication.products[0].productType.toInputOption()
          : isEclearSelected
          ? bidIndication.products
              .find(p => p.productType.isEclear())
              ?.productType.toInputOption()
          : undefined
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        bidIndication,
        bidProductType,
      })
    },
    onCreditSleeverAddClick() {
      this.creditSleeverIsShown = true
      this.$emit('credit-sleever-add')
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        creditSleever: undefined,
        creditSleeverAskBrokingFee: '0',
        creditSleeverBidBrokingFee: '0',
        creditSleeverSleeveSpread: '0',
      })
    },
    onCreditSleeverRemoveClick() {
      this.creditSleeverIsShown = false
      this.$emit('credit-sleever-remove')
      this.$emit(this.formInputEventName, {
        ...this.formValue,
        creditSleever: undefined,
        creditSleeverAskBrokingFee: undefined,
        creditSleeverBidBrokingFee: undefined,
        creditSleeverSleeveSpread: undefined,
      })
    },
  },
})
