
import { defineComponent, PropType } from 'vue'

import { Locale } from '@/api/generated'
import { PositionType } from '@/api/generated/api'
import BaseModal from '@/components/common/BaseModal.vue'
import SelectedIndication from '@/components/trading/SelectedIndication.vue'
import indicationSelectorModal from '@/components/trading/container/IndicationSelectorModal.vue'
import { Indication } from '@/models/trading/Indication'
import { ProductSubType } from '@/models/trading/ProductSubType'
import { ProductType } from '@/models/trading/ProductType'

export default defineComponent({
  name: 'IndicationSelector',
  components: {
    BaseModal,
    indicationSelectorModal,
    SelectedIndication,
  },
  props: {
    modalName: {
      type: String,
      default: 'indicationSelectorModal',
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    selectedIndication: {
      type: Object as PropType<Indication>,
      default: undefined,
    },
    otherIndication: {
      type: Object as PropType<Indication>,
      default: undefined,
    },
    otherProductType: {
      type: Object as PropType<ProductType>,
      default: undefined,
    },
    position: {
      type: String as PropType<PositionType>,
      required: true,
    },
    colorReversed: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String as PropType<Locale>,
      default: Locale.Ja,
    },
  },
  emits: ['select', 'indication-select'],
  computed: {
    fuelSurchargeTypeId(): string | undefined {
      return this.otherIndication?.fuelSurchargeType?.fuelSurchargeTypeId
    },
    otherDeliveryTermsId(): string | undefined {
      return this.otherIndication?.products[0]?.deliveryTerms.id
    },
    otherExchangeDeliveryTermsId(): string | undefined {
      return this.otherIndication?.products[0]?.exchangeDeliveryTerms?.id
    },
    otherProductSubType(): ProductSubType | undefined {
      return this.otherIndication?.productSubType
    },
  },
  methods: {
    onSelect(indication: Indication) {
      this.$emit('select', indication)
      this.hideModal()
    },
    onClick() {
      if (this.isSelectable) {
        this.showModal()
      }
    },
    onCancel() {
      this.hideModal()
    },
    showModal() {
      this.$vfm.open(this.modalName)
    },
    hideModal() {
      this.$vfm.close(this.modalName)
    },
    onIndicationSelect(indication: Indication) {
      this.$emit('indication-select', indication)
    },
  },
})
